<template>
	<span>
		<span v-html="$store.state.session.currency_symbol"></span><span v-if="type == 'total'">{{ display_value.integer }}.<span class="price-fraction">{{ display_value.fraction }}</span></span><span v-else>{{ display_value.total }}</span>
		<!-- /<span v-html="$store.state.session.currency_symbol_2"></span><span v-if="type == 'total'">{{ display_value.integer }}.<span class="price-fraction">{{ display_value_2.fraction }}</span></span><span v-else>{{ display_value_2.total }}</span>-->
	</span>
</template>

<script>
export default {
	props: [
		'value',
		'type'
	],
	computed: {
		display_value() {
			return {
				total: parseFloat(this.value).toLocaleString('en', {minimumFractionDigits: 2}),
				integer: parseInt(this.value).toLocaleString('en'),
				fraction: Math.round(this.value % 1 * 100).toString().padStart(2, "0")
			};
		},
		display_value_2() {
			let v = this.value * 0.84;
			v = v.toFixed(2);
			return {
				total: parseFloat(v).toLocaleString('en', {minimumFractionDigits: 2}),
				integer: parseInt(v).toLocaleString('en'),
				fraction: Math.round(v % 1 * 100).toString().padStart(2, "0")
			};
		}
	}
}
</script>

<style scoped>
.price-fraction {
	font-size: 0.7em;
}
</style>