export const state = () => ({
	hash: "",
	subcategories: {}
})

export const mutations = {
	set ( state, data ) {
		state.hash = data.hash;
		state.subcategories = data.subcategories;
		localStorage.setItem( 'subcategories', JSON.stringify( state ) );
	}
}