import Vue from 'vue';

Vue.filter('date', function(value) {
	if ( !value ) {
		return value;
	}
	var options = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	};
	return new Date( value ).toLocaleDateString('en-IE', options);
});