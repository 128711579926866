<template>
	<div>
		<header class="paper">
			<n-link to="/">
				<Logo/>
			</n-link>
		</header>
		<nuxt/>
		<footer>
			&copy; <a href="https://www.asagroup.ie">ASA Marketing Group</a> {{ current_year }}
		</footer>
	</div>
</template>

<script>
import Logo from '~/components/Logo.vue'
import Housekeeping from '~/components/Housekeeping.vue'

export default {
	components: {
		Logo,
		Housekeeping
	},
	data() {
		return {
			current_year: new Date().getFullYear()
		};
	},
	mounted() {
		switch ( this.$i18n.locale ) {
			case 'default': {
				// no subdomain so we let user pick
				return;
			}
			case 'us': {
				console.log('should load the US site');
				window.location = '/home';
				break;
			}
			case 'eu': {
				console.log('should load the EU site');
				window.location = '/home';
				break;
			}
		}
	}
}
</script>

<style scoped>
header {
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
footer {
	color: #ddd;
	font-size: 12px;
	padding: 16px;
	text-align: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
a {
	color: #eee;
	padding: 8px 0;
}
a:hover {
	color: #fff;
}
</style>